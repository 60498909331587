import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ArqueodecajasServices } from '../arqueodecaja/arqueodecajas.services';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { NotificationServices } from '../../appauth/notification/notificatio.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Arqueodecajas } from '../arqueodecaja/arqueodecajas.model';
import { AddArqueodecajaComponent } from '../modal/add/addarqueodecaja.component';
import { EditArqueodecajaComponent } from '../modal/edit/editarqueodecaja.component';
import { localStorageServices } from '../../appauth/auth/localStorageServices';
import { ListaComercialesServices, ListaImpresorasServices } from '../../global/glogal.services';

@Component({
	selector: 'ms-arqueodecajavista',
	templateUrl: './arqueodecajavista.component.html',
	styleUrls: ['./arqueodecajavista.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class ArqueodecajasVistaComponent implements OnInit {

	userdata: any;
	comercial: any

	rows = [];
	temp = [];
	ip: string = '127.0.0.1'

	config: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}

	configEdit: any = {
		size: 'md',
		centered: true,
		resolve: { datasend: null }
	}

	constructor(public dialog: NgbModal,
		private serviciosArqueodecajas: ArqueodecajasServices,
		private notificationServices: NotificationServices,
		private comprobantes: ListaComercialesServices,
		public imprimir: ListaImpresorasServices,
		public localdata: localStorageServices,
	) { }

	ngOnInit() {
		this.userdata = this.localdata.getValueFromLocalStorage();
		this.comercial = this.userdata.empresa;
		this.imprimir.viewAdministradoresImpresoras(this.userdata.grupo).subscribe((datadmin: any) => {
			if (datadmin.impresora != undefined) {
				this.ip = datadmin.impresora.ip;
			}
			this.listaArqueodecajas();
		})
	}

	listaArqueodecajas() {
		this.serviciosArqueodecajas.viewDiarioCajasLista(this.comercial.id, this.userdata.datos.persona.id).subscribe((data: any) => {
			this.rows = data.arqueodecajas;
			this.temp = [...this.rows];
		});
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function (d) {
			return d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
		});
		this.rows = temp;
	}

	open() {
		let dialogRef = this.dialog.open(AddArqueodecajaComponent, this.config);
		dialogRef.result.then(
			result => {
				if (result === 1) {
					this.listaArqueodecajas();
				}
			},
			reason => {
				console.log(reason);
			}
		);
	}

	edit(data: any) {
		let dialogRef = this.dialog.open(EditArqueodecajaComponent, this.configEdit);
		dialogRef.componentInstance.Arqueodecaja = data;
		dialogRef.result.then(
			result => {
				if (result === 1) {
					this.listaArqueodecajas();
				}
			},
			reason => {
				console.log(reason);
			}
		);
	}

	editEstado(dataArqueodecaja: Arqueodecajas) {
		let dataEstadoArqueodecaja = {} as Arqueodecajas;
		dataEstadoArqueodecaja.id = dataArqueodecaja.id;
		dataEstadoArqueodecaja.estado = Number(!dataArqueodecaja.estado);
		this.serviciosArqueodecajas.updateArqueodecajas(dataEstadoArqueodecaja).subscribe((data: any) => { });
	}

	printer(data: any) {
		console.log(data);
		
		let dataPrinterFactura = {
			data: {
				"comercial": { "nombreComercial": this.comercial.comerciale.nombrecomercial, "razonsocial": this.comercial.comerciale.razonsocial, "direccion": this.comercial.direccioncompleta, "ruc": this.comercial.comerciale.numeroruc, "obligado": this.comercial.comerciale.obligado },
				"valores": {'entrega':data.montoentrega},
				"tesorero": {
					"identificador": data.entrega.cedula,
					"nombre": data.entrega.nombre,
					"apellido": data.entrega.apellido
				}
			},
			ip: this.ip
		};
		this.comprobantes.printerMontoCierreCajaVenta(dataPrinterFactura).subscribe((info: any) => {
			console.log(info);
		});
	}
}

